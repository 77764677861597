<template>
  <div class="app-container">
    <eHeader
            :is-credits="true"
            @clear="clear"
            :query="query"
            @toQuery="toQuery"
    />
    <!--表格渲染-->
    <el-table
            v-loading="loading"
            :data="data"
            stripe
            size="small"
            style="width: 100%;"
    >
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="userName" label="客户昵称"/>
      <el-table-column prop="turnOutNum" label="申请转出数量"/>
      <el-table-column prop="activityName" label="活动名称"/>
      <el-table-column prop="giveMoneyNum" label="赠金数量"/>
      <el-table-column prop="successNumber" label="达标交易手数">
        <template slot-scope="scope">
          <span>{{getSuccessNumber(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="completeNumber" label="完成交易手数">
        <template slot-scope="scope">
          <span>{{getCompleteNumber(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="expireTime" label="到期时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.expireTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ADMIN','TCustBonusMt4_EDIT']) && scope.row.status == 0"
            type="danger"
            size="mini"
            @click="rejectHandle(scope.row.id)"
          >拒绝</el-button>
          <el-button
            v-if="checkPermission(['ADMIN','TCustBonusMt4_EDIT']) && scope.row.status == 0"
            type="danger"
            size="mini"
            @click="passHandle(scope.row)"
          >通过</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/finance/withgold/header'
import { parseWithdrawStatus,parseCurrencyType ,parseOutType,parsePayStatus} from '@/views/pt/ib/utils/index'
import { updateCreditsStatus } from '@/api/bussetup/withGold'
export default {
  name:'credits',
  components: { eHeader },
  mixins: [initData],
  props:{
  },
  data() {
    return {
      loading: false,sup_this: this,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseWithdrawStatus,
    parseOutType,
    parseCurrencyType,
    parsePayStatus,
    beforeInit() {
      this.url = '/crm/queryRollOutRecord'
      // const sort = 'id,desc'
      this.params = { page: this.page, size: this.size}
      const query = this.query
      const likeName = query.likeName;
      const status = query.status;
      const startTime = query.startTime;
      const endTime = query.endTime;
      likeName && (this.params.likeName = likeName);
      status && (this.params.status = status);
      if(status == 0){
        this.params.status = status
      }
      startTime && (this.params.startTime = this.parseTime(startTime));
      endTime && (this.params.endTime = this.parseTime(endTime));
      return true
    },
    rejectHandle(id){
      this.$confirm(`是否拒绝该账户信用额转出申请`,'确认拒绝', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        updateCreditsStatus({
          id: id,
          status: 2
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    passHandle(row){
      let confirmTitle = row.giveType != 3 ? '通过后，信用额将转为账户余额': '通过后，将进入出金流程'
      this.$confirm(`${confirmTitle}`,'确认通过', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        updateCreditsStatus({
          id: row.id,
          status: 1
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    getSuccessNumber(row){
      if(row.giveType == 2){
        return '---'
      }else {
        return row.successNumber / 100;
      }
    },
    getCompleteNumber(row){
      if(row.giveType == 2){
        return '---'
      }else {
        return row.completeNumber && row.completeNumber / 100;
      }
    },
  //  状态 0-待审核 1-已通过 2-已拒绝 3-已过期
    getStatus(status){
      let text = '';
      switch (status) {
        case 0:
          text = '待审核';
          break;
        case 1:
          text = '已通过';
          break;
        case 2:
          text = '已拒绝';
          break;
        case 3:
          text = '已过期';
          break;
      }
      return text;
    },
    clear(){
      this.query.likeName = '';
      this.query.status = '';
      this.query.startTime = '';
      this.query.endTime = '';
      this.toQuery();
    },
  }
}
</script>

<style scoped>

</style>
